<template>
    <auth-layout>
        <error-view
            image-path-in-assets="bottle.svg"
            :title="title"
            :show-title="true"
            :description="description"
            :cta="buttonCta"
            @click="buttonClicked"
        />
    </auth-layout>
</template>

<script>
    import AuthLayout from '@/layouts/Auth'
    import ErrorView from '@/components/ErrorView'
    import { isSafari } from '@/utils/parseUserAgents'

    export default {
        name: 'AppBlocked',
        components: {
            ErrorView,
            AuthLayout,
        },
        data() {
            return {
                title: {
                    type: String,
                    required: true,
                },
                description: {
                    type: String,
                    required: true,
                },
                cta: String,
                buttonActionUrl: String,
            }
        },
        created() {
            this.title = this.$route.params.title
            this.description = this.$route.params.description
            this.buttonCta = this.$route.params.buttonText
            this.buttonActionUrl = this.$route.params.buttonActionUrl
        },
        methods: {
            buttonClicked: function () {
                if (this.isWebView) {
                    window.location.href = this.buttonActionUrl
                    return
                }
                if (isSafari()) {
                    window.location.href = this.buttonActionUrl
                } else {
                    window.open(this.buttonActionUrl, '_blank')
                }
            },
        },
    }
</script>

<style lang="scss" scoped></style>
